var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"issues-tab-container"},[_c('TableHeaderFilter',{attrs:{"headers":_vm.headers,"selectedHeaders":_vm.selectedHeaders,"menuActivator":"#issues-col-filter"},on:{"changeHeaders":_vm.changeSelectedHeaders}}),_c('DynamicTable',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.issues,"loading":_vm.issuesLoading,"hasFooter":true,"tabSelected":"issues","pageType":"turbine","menuActivator":"issues-col-filter"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link-cell",attrs:{"to":{
          name: 'NewIssueDetail',
          params: {
            issueId: item.id,
          },
        }}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"link-text"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"mr-1 pointer"},[_c('v-icon',{attrs:{"size":"1.25rem"}},[_vm._v("mdi-open-in-new")])],1)])])]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('span',{staticClass:"issue-status",style:({
            'background-color': _vm.generateStatusColor(
              item.current_status_def_id
            ),
          })},[_vm._v(" "+_vm._s(item.status))])])]}},{key:"ticketsInProgress",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap align-center"},[_vm._l((item.ticketsInProgress),function(ticket){return _c('div',{key:ticket},[(item.ticketsInProgress.length <= 2)?_c('div',[_c('router-link',{attrs:{"to":{
                name: 'NewIssueDetail',
                params: {
                  issueId: item.id,
                },
              }}},[_c('div',{staticClass:"issue-in-progress mr-1"},[_vm._v(" "+_vm._s(ticket)+" ")])])],1):_vm._e()])}),(item.ticketsInProgress && item.ticketsInProgress.length > 2)?_c('div',{staticClass:"show-all-issues-container"},[_c('div',{staticClass:"show-all-issues-display ml-2",on:{"click":function($event){return _vm.showAllModal(item)}}},[_vm._v(" "+_vm._s(_vm.showAllIssuesInProgress ? "Show less" : "Show all")+" ")]),(_vm.showAllIssuesInProgress && _vm.selectedItem === item)?_c('div',{staticClass:"all-issues"},[_c('div',{staticClass:"d-flex justify-space-around"},_vm._l((_vm.selectedItem.ticketsInProgress),function(ticket){return _c('p',{key:ticket,staticClass:"ma-0"},[_c('router-link',{attrs:{"to":{
                    name: 'NewIssueDetail',
                    params: {
                      issueId: item.id,
                    },
                  }}},[_c('div',{staticClass:"issue-in-progress mr-1"},[_vm._v(" "+_vm._s(ticket)+" ")])])],1)}),0)]):_vm._e()]):_vm._e()],2)]}},{key:"impact_mwh",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.impact_mwh ? item.impact_mwh : 0)+" MWh")]),_c('span',{staticClass:"ml-1 mr-1"},[_vm._v("/")]),_c('span',[_vm._v("$"+_vm._s(item.impact_usd ? item.impact_usd : 0))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }