var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"systems-tab-container"},[_c('TableHeaderFilter',{attrs:{"headers":_vm.headers,"selectedHeaders":_vm.selectedHeaders,"menuActivator":"#systems-col-filter"},on:{"changeHeaders":_vm.changeSelectedHeaders}}),_c('DynamicTable',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.systems,"loading":_vm.systemsLoading,"hasFooter":true,"tabSelected":"systems","menuActivator":"systems-col-filter"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link-cell",attrs:{"to":_vm.goToComponentPage(item.name)}},[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('div',{staticClass:"link-text"},[_vm._v(" "+_vm._s(_vm.transformName(item.name, "display", false))+" ")]),_c('div',{staticClass:"mr-1 pointer open-in-new-icon"},[_c('v-icon',{attrs:{"size":"1.25rem"}},[_vm._v("mdi-open-in-new")])],1)])])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-item"},[_c('v-icon',{attrs:{"color":_vm.setStatusColor(item.status)}},[_vm._v("mdi-circle-medium")])],1)]}},{key:"ticketsInProgress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap align-center"},[_vm._l((item.ticketsInProgress),function(ticket){return _c('div',{key:ticket.work_order_id},[(item.ticketsInProgress.length <= 2)?_c('div',[_c('router-link',{attrs:{"to":{
                name: 'NewIssueDetail',
                params: {
                  issueId: ticket.turbine_issue_id,
                },
              }}},[_c('div',{staticClass:"issue-in-progress mr-1"},[_vm._v(" "+_vm._s(ticket.work_order_id)+" ")])])],1):_vm._e()])}),(item.ticketsInProgress && item.ticketsInProgress.length > 2)?_c('div',{staticClass:"show-all-issues-container"},[_c('div',{staticClass:"show-all-issues-display ml-2",on:{"click":function($event){return _vm.showAllModal(item)}}},[_vm._v(" "+_vm._s(_vm.showAllIssuesInProgress ? "Show less" : "Show all")+" ")]),(_vm.showAllIssuesInProgress && _vm.selectedItem === item)?_c('div',{staticClass:"all-issues"},[_c('div',{staticClass:"d-flex justify-space-around"},_vm._l((_vm.selectedItem.ticketsInProgress),function(ticket){return _c('p',{key:ticket.work_order_id,staticClass:"ma-0"},[_c('router-link',{attrs:{"to":{
                    name: 'NewIssueDetail',
                    params: {
                      issueId: ticket.turbine_issue_id,
                    },
                  }}},[_c('div',{staticClass:"issue-in-progress mr-1"},[_vm._v(" "+_vm._s(ticket.work_order_id)+" ")])])],1)}),0)]):_vm._e()]):_vm._e()],2)]}},{key:"impact_mwh",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.impact_mwh)+" MWh")]),_c('span',[_vm._v("/")]),_c('span',{staticClass:"ml-1"},[_vm._v("$")]),_vm._v(_vm._s(item.impact_usd)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }