<template>
  <div>
    <header>
      <p class="ma-0">{{ siteName }}</p>
      <div class="turbine-dashboard__header d-flex align-center">
        <h3 class="d-flex align-center main-title">
          <div
            class="d-flex justify-center align-center main-title-spinner"
            v-if="loading.getTurbine"
          >
            <v-progress-circular
              :size="25"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>{{ turbineName }}</div>
        </h3>
        <div
          class="turbine-dashboard__header__help d-flex align-center justify-space-between"
        >
          <v-icon @click="handleClickInfoIcon">{{ informationIcon }}</v-icon>
        </div>
        <div
          class="d-flex justify-center align-center mb-2 component-spinner"
          v-if="loadingIssuesByComponent"
        >
          <v-progress-circular
            :size="25"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else class="d-flex">
          <div
            v-for="system in systems"
            :key="system.name"
            class="mx-1 component-button"
          >
            <v-tooltip top :open-delay="popupDelay">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <router-link :to="goToComponentPage(system.name)">
                    <svg
                      viewBox="0 0 28 28"
                      width="28px"
                      height="28px"
                      preserveAspectRatio="xMidYMid meet"
                      ref="svgObject"
                    >
                      <image
                        width="28px"
                        height="28px"
                        :href="systemIcon(system.name)"
                      />
                    </svg>
                  </router-link>
                </div>
              </template>
              <span>{{
                `Go to ${transformName(system.name, "display", false)} page`
              }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </header>
    <div class="outer-grid">
      <div class="row-1-col-1">
        <div class="map-container mt-1">
          <MapView
            v-if="mapData"
            :mapData="mapData"
            :isMapLoading="turbinesLoading && !summaryDataValid"
            height="374px"
          />
        </div>
      </div>
      <div class="row-1-col-2">
        <AtAGlance
          :page="'turbine'"
          :issuesLoading="loading.getPaginatedIssuesForIssuesTable"
        >
          <!-- Need to figure out where this data is coming from -->
          <!-- <template v-slot:header>
              <div class="d-flex heading-text mb-1">Updated 2023-11-30</div>
            </template> -->
          <template v-slot:content>
            <div class="content-container ml-1">
              <div
                class="d-flex align-center justify-space-between orientation-header mt-2 mb-6"
              >
                <!-- <p class="d-flex">
                    IN THE LAST
                    <span class="days-menu mr-1 ml-1 pointer"
                      ><p class="mb-0">30</p>
                      <v-menu
                        offset-x
                        left
                        offset-overflow
                        attach=".select-days-btn"
                        activator=".select-days-btn"
                        v-if="openMenu"
                      >
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in days"
                            :key="index"
                          >
                            <v-list-item-title
                              @click="updateDays(item.value)"
                              >{{ item.text }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </span>
                    DAYS
                  </p> -->
                <p>ALL ISSUES</p>
                <!-- <button class="select-days-btn" @click="openMenu = true">
                    SELECT DAYS
                  </button> -->
              </div>
              <p class="mb-3 content-body error--text">
                {{ criticalIssuesCount }} critical issues
              </p>
              <div>
                <p class="at-a-glance-body-footer">
                  <span class="new-issues">{{ newIssuesCount }} new</span
                  ><span class="mr-1">,</span>
                  <span class="in-progress-issues"
                    >{{ inProgressIssuesCount }} in progress</span
                  ><span class="mr-1">,</span>
                  <span class="closed-issues"
                    >{{ closedIssuesCount }} closed</span
                  >
                </p>
                <!-- <p>Data: 98%</p> -->
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="top-recommendation-cards d-flex justify-space-between">
              <div class="top-recommendation-cards__card1">
                <RecommendationCard
                  :issue="highestAEPRecommendation"
                  type="highestAEP"
                  :siteId="siteId"
                />
              </div>
              <div class="top-recommendation-cards__card2">
                <RecommendationCard
                  :issue="assetRecommendation"
                  type="asset"
                  :siteId="siteId"
                />
              </div>
            </div>
          </template>
        </AtAGlance>
      </div>
      <div class="row-2-col-1-2">
        <div class="new-tab">
          <button
            v-for="tab in tabs"
            :key="tab.id"
            :class="{ tablinks: true, active: activeTab === tab.id }"
            @click="openTab(tab.id, tab.name, 'click_event')"
          >
            {{ tab.name }}
          </button>
        </div>
        <div class="tabbed-section">
          <div id="Issues" class="issues-tab" v-if="activeTab === 1">
            <TurbineDashboardIssuesTab
              :issuesLoading="loading.getPaginatedIssuesForIssuesTable"
              :issues="issuesSorted"
              :siteId="siteId"
            />
          </div>
          <div id="Systems" class="tabcontent" v-if="activeTab === 2">
            <TurbineDashboardSystemsTab
              :systems="systemsSorted"
              :systemsLoading="systemsLoading"
              :turbineId="$route.params.turbineId"
            />
          </div>
          <div id="Explore" class="tabcontent pa-4" v-if="activeTab === 3">
            <div v-if="!isPowerCurveExplorerOpen && !isEventsViewOpen">
              <NewAnalyticsView
                ref="analyticsView"
                :turbineData="turbinesData"
                :siteId="siteId"
                pageTitle="newTurbineDashboard"
                @setStaticViewRequest="setAnalyticsViewRequest"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
            <div v-if="isPowerCurveExplorerOpen && !isEventsViewOpen">
              <NewPowerCurveExplorer
                :targetTurbineOptions="targetTurbineOptions"
                :loadingTurbines="turbinesLoading"
                :siteId="siteId"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
            <div v-if="isEventsViewOpen && !isPowerCurveExplorerOpen">
              <EventsExplorer
                page="newTurbineDashboard"
                :siteId="siteId"
                @openEventsView="toggleEventsView"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="outer-grid-2">
      <div class="grid-2-row-1-col-1">
        <AllIssuesCard
          :siteName="siteName"
          :issuesByStatus="issuesByStatus"
          :loading="loading.getPaginatedIssuesForIssuesTable"
          page="turbineDashboard"
          @goToIssues="goToIssuesTable"
        ></AllIssuesCard>
      </div>
      <div class="grid-2-row-1-col-2">
        <MaintenanceCard
          :maintenanceData="maintenanceData"
          :siteName="siteName"
          :loading="loadingGetMaintenanceData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axiosAPI";
import { mapActions, mapState } from "vuex";
import {
  informationIcon,
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  popupDelay,
} from "@/helpers/variables";
import { roundToString, transformName } from "@/helpers/functions";
import NewAnalyticsView from "@/components/NewIssueDetailComponents/NewAnalyticsView";
import NewPowerCurveExplorer from "@/components/NewIssueDetailComponents/NewPowerCurveExplorer";
import EventsExplorer from "@/components/NewSiteDashboardComponents/EventsExplorer";
import TurbineDashboardIssuesTab from "@/components/NewTurbineDashboardComponents/TurbineDashboardIssuesTab";
import TurbineDashboardSystemsTab from "@/components/NewTurbineDashboardComponents/TurbineDashboardSystemsTab";
import AtAGlance from "@/components/SummaryCards/AtAGlance";
import RecommendationCard from "@/components/RecommendationCard";
import AllIssuesCard from "../components/AllIssuesCard.vue";
import MaintenanceCard from "@/components/SummaryCards/MaintenanceCard";
import MapView from "@/components/MapView";
import L from "leaflet";
import dayjs from "dayjs";
import { COLORS } from "../helpers/colors";
import mixpanel from "mixpanel-browser";

export default {
  name: "NewTurbineDashboard",
  components: {
    NewAnalyticsView,
    NewPowerCurveExplorer,
    TurbineDashboardIssuesTab,
    TurbineDashboardSystemsTab,
    AtAGlance,
    AllIssuesCard,
    MaintenanceCard,
    RecommendationCard,
    MapView,
    EventsExplorer,
  },
  data() {
    return {
      informationIcon,
      tabs: [
        {
          id: 1,
          name: "ISSUES",
        },
        {
          id: 2,
          name: "SYSTEMS",
        },
        {
          id: 3,
          name: "EXPLORE",
        },
      ],
      days: [
        {
          text: "7 days",
          value: 7,
        },
        {
          text: "30 days",
          value: 30,
        },
      ],
      systemsLoading: false,
      activeTab: 1,
      isPowerCurveExplorerOpen: false,
      staticAnalyticsViewRequest: {},
      maintenanceList: null,
      loadingGetMaintenanceData: false,
      openMenu: true,
      selectedDays: 7,
      mapData: {
        zoom: 15,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
        markers: [],
      },
      issuesByComponent: [],
      loadingIssuesByComponent: false,
      popupDelay,
      isEventsViewOpen: false,
    };
  },
  computed: {
    ...mapState({
      issuesByStatus: (state) => state.turbine.paginatedIssuesForIssuesTable,
      turbineChecksData: (state) => state.turbine.checks,
      checksLoading: (state) => state.turbine.loading.getChecks,
      turbineData: (state) => state.turbine.turbineData,
      clickCount: (state) => state.app.clickCount,
      userData: (state) => state.user.userData,
      loading: (state) => state.turbine.loading,
      turbinesData: (state) => state.site.turbinesData,
      turbinesLoading: (state) => state.site.loading.getTurbinesBySiteId,
    }),
    siteName() {
      return this.turbineData.farm ? this.turbineData.farm.name : "Site Name";
    },
    siteId() {
      return this.turbineData.farm ? this.turbineData.farm.id : null;
    },
    turbineName() {
      return this.turbineData?.shortname
        ? `Turbine ${this.turbineData.shortname}`
        : "";
    },
    subscription() {
      return this.turbineData?.subscription;
    },
    // For the turbine map at top left of screen
    targetTurbineOptions() {
      if (this.turbinesData.length > 0) {
        const options = this.turbinesData.map((turbine) => ({
          id: turbine.id,
          text: turbine.turbine_name,
          value: turbine.id,
          position: turbine.position,
          site_id: turbine.site_id,
          turbine_model: turbine.turbine_model,
          mapInfoTitle: turbine.mapInfoTitle,
          color: "grey",
        }));
        return options;
      } else {
        return [];
      }
    },
    issues() {
      if (this.issuesByStatus && this.issuesByStatus.length > 0) {
        const issuesMap = this.issuesByStatus.map((issue) => ({
          id: issue.id,
          name: issue.name,
          description: issue.description,
          status: issue.current_status_def_name_external,
          current_status_def_id: issue.current_status_def_id,
          impact_mwh: roundToString(issue.aep_loss_mwhpyr_open, 0),
          impact_usd: roundToString(issue.aep_loss_upper_usdpyr_open, 0),
          ticketsInProgress: issue.work_order_ids,
          last_updated: dayjs(issue.current_status_created_ts).format(
            "YYYY-MM-DD",
          ),
          is_open: issue.is_open,
        }));
        return issuesMap;
      }
      return [];
    },
    issuesSorted() {
      if (this.issues.length > 0) {
        return this.sortItems(this.issues, "issues");
      }
      return [];
    },
    criticalIssuesCount() {
      if (this.issuesByStatus.length > 0) {
        return this.newIssuesCount + this.inProgressIssuesCount;
      }
      return 0;
    },
    newIssuesCount() {
      if (this.issuesByStatus.length > 0) {
        return this.issuesByStatus.filter(
          (issue) => issue.current_status_def_name_external === "New",
        ).length;
      }
      return 0;
    },
    inProgressIssuesCount() {
      if (this.issuesByStatus.length > 0) {
        return this.issuesByStatus.filter(
          (issue) => issue.current_status_def_name_external === "In progress",
        ).length;
      }
      return 0;
    },
    closedIssuesCount() {
      if (this.issuesByStatus.length > 0) {
        return this.issuesByStatus.filter(
          (issue) => issue.current_status_def_name_external === "Closed",
        ).length;
      }
      return 0;
    },
    maintenanceData() {
      return this.maintenanceList;
    },
    openIssues() {
      if (this.issues.length > 0) {
        return this.issues.filter((issue) => issue.is_open === true);
      }
      return [];
    },
    highestAEPRecommendation() {
      if (this.openIssues.length > 0) {
        const highestAEP = this.openIssues.reduce((prev, current) =>
          prev.impact_mwh >= current.impact_mwh ? prev : current,
        );
        return highestAEP;
      }
      return null;
    },
    assetRecommendation() {
      if (this.openIssues.length > 0) {
        // Look for component issues
        const assetIssue = this.openIssues.find(
          (issue) =>
            issue.name.includes("Main bearing") ||
            issue.description.includes("Main bearing") ||
            issue.name.includes("Converter") ||
            issue.description.includes("Converter"),
        );
        const highestAEP = this.highestAEPRecommendation;
        if (assetIssue && (!highestAEP || assetIssue.id !== highestAEP.id)) {
          return assetIssue;
        }
        const fallbackIssue = this.openIssues.find(
          (issue) => !highestAEP || issue.id !== highestAEP.id,
        );
        return fallbackIssue || null;
      } else {
        return null;
      }
    },
    summaryDataValid() {
      if (
        this.turbineData &&
        this.turbineData.id == this.$route.params.turbineId &&
        this.turbineData.subscription
      ) {
        return true;
      } else {
        return false;
      }
    },
    systems() {
      let issues = [];
      if (this.issuesByComponent?.results?.length > 0) {
        issues = this.issuesByComponent.results.map((issue) => {
          return {
            name:
              issue.component_name === "main_bearing"
                ? "rotor_shaft_assembly"
                : issue.component_name,
            newIssues: issue.n_new ? issue.n_new : "0",
            status: this.setStatusColor(issue.n_open),
            ticketsInProgress: Object.entries(
              issue.work_order_id_to_turbine_issue_id,
            ).map(([workOrderId, turbineIssueId]) => ({
              work_order_id: workOrderId,
              turbine_issue_id: turbineIssueId,
            })),
            impact_mwh: roundToString(issue.aep_loss_mwhpyr_open, 0),
            impact_usd: roundToString(issue.aep_loss_upper_usdpyr_open, 0),
            totalIssues: issue.n ? issue.n : "0",
            openIssues: issue.n_open ? issue.n_open : "0",
            issuesClosed: issue.n_fixed_or_resolved
              ? issue.n_fixed_or_resolved
              : "0",
            lastUpdated: issue.last_updated_ts
              ? dayjs(issue.last_updated_ts).format("YYYY-MM-DD")
              : "None",
          };
        });
      }
      return issues;
    },
    systemsSorted() {
      if (this.systems.length > 0) {
        return this.sortItems(this.systems, "systems");
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      getTurbineData: "turbine/getTurbine",
      incrementClickCount: "app/incrementClickCount",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
      getTurbinesBySiteId: "site/getTurbinesBySiteId",
      getIssuesByStatus: "turbine/getPaginatedIssuesForIssuesTable",
    }),
    setMapData() {
      const turbineId = parseInt(this.$route.params.turbineId);
      let mapObj = {
        zoom: 12,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
      };
      if (this.turbinesData?.length > 0) {
        const turbineArr = this.turbinesData.map((item) => {
          return {
            id: item.id,
            site_id: item.site_id,
            org_id: item.org_id,
            turbine_name: item.turbine_name,
            turbine_model: item.turbine_model,
            issues_that_need_attention: item.issues_that_need_attention
              ? item.issues_that_need_attention
              : 0,
            open_issues: item.open_issues ? item.open_issues : 0,
            loss_upper_usd: item.loss_upper_usd
              ? Math.round(item.loss_upper_usd)
              : null,
            aep_loss: item.aep_loss !== undefined ? item.aep_loss : null,
            color: item.id === turbineId ? COLORS.blue : COLORS.gray,
            position: item.position
              ? L.latLng(item.position.lat, item.position.lng)
              : {},
            selected: false,
            mapInfoTitle: item.mapInfoTitle,
            mapInfoDesc: item.mapInfoDesc,
            mapInfoRoute: item.mapInfoRoute,
            turbine_issue_ids_open: item.turbine_issue_ids_open,
            turbine_issue_ids_require_external_input:
              item.turbine_issue_ids_require_external_input,
          };
        });

        mapObj.markers = turbineArr;
      }
      this.mapData = mapObj;
    },
    handleClickInfoIcon() {
      this.updateShowBurger(true);
      this.updateBurgerData({
        isSubscription: Boolean(this.subscription),
        name: "Subscription: " + this.subscription?.definition?.name,
        endDate: this.subscription?.end_date,
        startDate: this.subscription?.start_date,
        description: this.subscription?.definition?.description,
      });
    },
    openTab(tabId, tabName, method) {
      const url = new URL(window.location.href);
      const analyticsView = url.searchParams.get("analytics_view");
      if (method !== "method") {
        mixpanel.track("new_turbine_dashboard_tab_click", {
          tab_name: tabName,
        });
      }
      this.activeTab = tabId;
      if (tabId === 3) {
        if (this.siteId) {
          this.goToExploreTab();
          if (analyticsView === "events") {
            this.isEventsViewOpen = true;
          } else if (analyticsView === "power_curve_explorer") {
            this.isPowerCurveExplorerOpen = true;
          }
        } else {
          this.openExploreTab = true;
        }
      }
    },
    isActiveTab(tabId) {
      return this.activeTab === tabId;
    },
    setAnalyticsViewRequest(request) {
      // Request details for setting url params on navigation to site analytics tab
      this.staticAnalyticsViewRequest.site_id = request.site_id;
      this.staticAnalyticsViewRequest.start_date = request.start_date;
      this.staticAnalyticsViewRequest.end_date = request.end_date;
      this.staticAnalyticsViewRequest.turbine_ids = request.turbine_ids;
      this.staticAnalyticsViewRequest.view = request.view;
    },
    togglePowerCurveExplorer(isOpen) {
      this.isPowerCurveExplorerOpen = isOpen;
    },
    toggleEventsView(isOpen) {
      this.isEventsViewOpen = isOpen;
    },
    updateAnalyticsViewSelected(view) {
      if (view === "events") {
        this.toggleEventsView(true);
      }
    },
    goToExploreTab() {
      if (
        this.siteId &&
        (this.turbinesData?.length === 0 ||
          this.siteId !== this.turbinesData[0]?.site_id)
      ) {
        this.getTurbinesBySiteId(this.siteId);
      }
      this.openExploreTab = false;
    },
    goToIssuesTable(payload) {
      this.$router.push({
        name: "SiteDashboard",
        params: {
          siteId: this.siteId,
        },
        query: payload === "default" ? null : payload,
        hash: "#issues",
      });
    },
    updateDays(selection) {
      this.selectedDays = selection;
      this.openMenu = false;
      // Call API here for issues from the last x days
    },
    setStatusColor(issues) {
      if (issues === 0) {
        return "green";
      } else if (issues > 0 && issues < 5) {
        return "warning";
      } else {
        return "error";
      }
    },
    sortItems(array, mode) {
      if (mode === "issues") {
        return array.sort((a, b) => {
          const dateA = dayjs(a.last_updated);
          const dateB = dayjs(b.last_updated);

          if (dateA.isAfter(dateB)) {
            return -1;
          } else if (dateA.isBefore(dateB)) {
            return 1;
          }
          if (a.impact_mwh > b.impact_mwh) {
            return 1;
          } else if (a.impact_mwh < b.impact_mwh) {
            return -1;
          }
          return 0;
        });
      } else if (mode === "systems") {
        return array.sort((a, b) => {
          if (a.issuesOpen > b.issuesOpen) {
            return 1;
          } else if (a.issuesOpen < b.issuesOpen) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    },
    systemIcon(componentName) {
      const locations = {
        converter: "/component_icons/converter-icon.svg",
        rotor_shaft_assembly: "/component_icons/main-bearing-icon.svg",
      };
      return locations[componentName] || "";
    },
    transformName,
    goToComponentPage(name) {
      const transformedName = this.transformName(name, "route", false);
      return {
        name: "SubsystemComponentDetail",
        params: {
          turbineId: this.$route.params.turbineId,
          componentName: transformedName,
        },
      };
    },
    // API calls
    async getMaintenanceData() {
      try {
        this.loadingGetMaintenanceData = true;
        const turbineId = parseInt(this.$route.params.turbineId);

        const res = await axios.get(`/turbines/${turbineId}/mods`);

        if (res.status === 200) {
          this.maintenanceList = res.data;
        } else {
          const error = {
            name: "getMaintenanceData",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingGetMaintenanceData = false;
    },
    async getIssuesGroupedByComponent() {
      const turbineId = parseInt(this.$route.params.turbineId);
      try {
        this.loadingIssuesByComponent = true;
        const params = {
          group_by: "component",
          get_losses_gains: true,
        };
        const res = await axios.get(`/turbines/${turbineId}/issues`, {
          params,
        });

        if (res.status === 200) {
          this.issuesByComponent = res.data;
        } else {
          const error = {
            name: "getIssuesGroupedByComponent",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingIssuesByComponent = false;
    },
  },
  beforeMount() {
    const turbineId = parseInt(this.$route.params.turbineId);
    // Only call for turbine data if no data or turbine id does not match route
    if (
      Object.keys(this.turbineData)?.length === 0 ||
      this.turbineData?.id !== turbineId
    ) {
      this.getTurbineData({
        turbineId: turbineId,
        getIssuesSummary: 1,
        getSubscription: 1,
        recursive: 1,
      });
    }
    if (this.maintenanceData?.length === 0 || this.maintenanceData === null) {
      this.getMaintenanceData();
    }
    // Call every mount until we have migrated to all new pages
    // if (
    //   !this.loadingGetIssuesByStatus &&
    //   (this.issuesByStatus.length === 0 ||
    //     this.issuesByStatus[0].turbine_id !== turbineId)
    // ) {
    const params = {
      turbineId: turbineId,
      include_statuses: [
        "new",
        "in progress",
        "fixed",
        "dismissed",
        "invalidated",
        "unconfirmed",
        "superseded",
        "deferred",
        "dismissed internal",
        "needs_review",
      ],
      new_page: "turbine",
    };
    this.getIssuesByStatus(params);
    if (this.issuesByComponent.length === 0) {
      this.getIssuesGroupedByComponent();
    }
  },
  watch: {
    turbineData: {
      immediate: true,
      handler(data) {
        if (
          data &&
          Object.keys(data).length > 0 &&
          data.issues_summary &&
          data.subscription
        ) {
          if (
            this.siteId &&
            (this.siteId !== this.turbinesData[0]?.site_id ||
              this.turbinesData?.length === 0)
          ) {
            this.getTurbinesBySiteId(this.siteId);
            return;
          }
        }
      },
    },
    turbinesData: {
      immediate: true,
      handler(data) {
        if (data && data.length > 0) {
          this.setMapData();
        }
      },
    },
    // Open explore tab only after site id is available
    siteId: {
      immediate: true,
      handler(value) {
        const url = new URL(window.location.href);
        const analyticsView = url.searchParams.get("analytics_view");
        if (analyticsView === "events") {
          this.isEventsViewOpen = true;
        } else {
          this.isEventsViewOpen = false;
        }
        if (value && this.openExploreTab) {
          this.goToExploreTab();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.turbine-dashboard {
  &__header {
    &__help {
      color: var(--v-black3-base);
      box-sizing: border-box;
      padding: 2px 16px 8px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
      .v-icon:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.outer-grid {
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  grid-template-rows: 378px 1fr;
  gap: 0.5rem;
}
.row-1-col-1 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.row-1-col-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.row-2-col-1-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}

header {
  p {
    font:
      0.75rem "Museo Sans Rounded",
      sans-serif;
    color: var(--v-text-base);
    font-weight: 600;
  }
}

.map-container {
  height: 374px;
  width: 100%;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  overflow: hidden;
}

.heading-text {
  font:
    0.75rem Roboto,
    sans-serif;
  color: var(--v-black8-base);
  margin-top: 0.7rem;
}

.main-title-spinner {
  min-width: 7rem;
}
.component-spinner {
  min-width: 5rem;
}

.content-container {
  position: relative;
  height: 110px;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.content-body {
  font:
    1.75rem Inter,
    sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}

.top-recommendation-cards {
  width: 100%;
  margin-top: 0.75rem;

  &__card1 {
    width: 49%;
  }
  &__card2 {
    width: 49%;
  }
}
.select-days-btn {
  border: 1.75px solid var(--v-inverse-base);
  border-radius: 3rem;
  padding: 0.1rem 0.6rem 0.1rem 0.6rem;
}
.select-days-btn:hover {
  background-color: var(--v-converterBorders-base);
}

.outer-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.tabbed-section {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  border-top-left-radius: 0;
  min-height: 150px;
}

.grid-2-row-1-col-1 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.grid-2-row-1-col-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}

@media (max-width: 1264px) {
  .outer-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 0.5rem;
  }
  .row-1-col-1 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .row-1-col-2 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 0;
  }
  .row-2-col-1-2 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
</style>
