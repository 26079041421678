<template>
  <div class="systems-tab-container">
    <TableHeaderFilter
      :headers="headers"
      :selectedHeaders="selectedHeaders"
      menuActivator="#systems-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="systems"
      :loading="systemsLoading"
      :hasFooter="true"
      tabSelected="systems"
      menuActivator="systems-col-filter"
    >
      <template v-slot:name="{ item }">
        <router-link :to="goToComponentPage(item.name)" class="link-cell">
          <div class="d-flex justify-space-between flex-wrap">
            <div class="link-text">
              {{ transformName(item.name, "display", false) }}
            </div>
            <div class="mr-1 pointer open-in-new-icon">
              <v-icon size="1.25rem">mdi-open-in-new</v-icon>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:status="{ item }">
        <div class="status-item">
          <v-icon :color="setStatusColor(item.status)"
            >mdi-circle-medium</v-icon
          >
        </div>
      </template>
      <template v-slot:ticketsInProgress="{ item }">
        <div class="d-flex flex-wrap align-center">
          <div
            v-for="ticket in item.ticketsInProgress"
            :key="ticket.work_order_id"
          >
            <div v-if="item.ticketsInProgress.length <= 2">
              <router-link
                :to="{
                  name: 'NewIssueDetail',
                  params: {
                    issueId: ticket.turbine_issue_id,
                  },
                }"
              >
                <div class="issue-in-progress mr-1">
                  {{ ticket.work_order_id }}
                </div></router-link
              >
            </div>
          </div>
          <div
            class="show-all-issues-container"
            v-if="item.ticketsInProgress && item.ticketsInProgress.length > 2"
          >
            <div
              @click="showAllModal(item)"
              class="show-all-issues-display ml-2"
            >
              {{ showAllIssuesInProgress ? "Show less" : "Show all" }}
            </div>
            <div
              v-if="showAllIssuesInProgress && selectedItem === item"
              class="all-issues"
            >
              <div class="d-flex justify-space-around">
                <p
                  class="ma-0"
                  v-for="ticket in selectedItem.ticketsInProgress"
                  :key="ticket.work_order_id"
                >
                  <router-link
                    :to="{
                      name: 'NewIssueDetail',
                      params: {
                        issueId: ticket.turbine_issue_id,
                      },
                    }"
                  >
                    <div class="issue-in-progress mr-1">
                      {{ ticket.work_order_id }}
                    </div></router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:impact_mwh="{ item }">
        <div>
          <span class="mr-1">{{ item.impact_mwh }} MWh</span><span>/</span
          ><span class="ml-1">$</span>{{ item.impact_usd }}
        </div>
      </template>
    </DynamicTable>
  </div>
</template>

<script>
import DynamicTable from "@/components/DynamicTable";
import { transformName } from "@/helpers/functions";
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import debounce from "lodash/debounce";

export default {
  name: "TurbineDashboardSystemsTab",
  components: {
    DynamicTable,
    TableHeaderFilter,
  },
  props: {
    systems: {
      type: Array,
      required: true,
    },
    systemsLoading: {
      type: Boolean,
      required: true,
    },
    turbineId: {
      type: [String, Number],
      required: false,
      detault: null,
    },
  },
  data() {
    return {
      currentPage: 1,
      rowsPerPage: 5,
      showAllIssuesInProgress: false,
      selectedIssue: null,
      headers: [
        {
          id: "systems_1",
          text: "NAME",
          value: "name",
        },
        {
          id: "systems_2",
          text: "STATUS",
          value: "status",
        },
        {
          id: "systems_3",
          text: "OPEN ISSUES",
          value: "openIssues",
        },
        {
          id: "systems_4",
          text: "TICKETS IN PROGRESS",
          value: "ticketsInProgress",
          not_sortable: true,
        },
        {
          id: "systems_5",
          text: "IMPACT (MWh/$)",
          value: "impact_mwh",
        },
        {
          id: "systems_6",
          text: "CLOSED ISSUES",
          value: "issuesClosed",
        },
        {
          id: "systems_7",
          text: "LAST UPDATED",
          value: "lastUpdated",
        },
      ],
      selectedColumns: [],
      maxColumns: 7,
    };
  },
  computed: {
    selectedHeaders() {
      let result = this.headers;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    showAllModal(item) {
      this.selectedItem = item;
      this.showAllIssuesInProgress = !this.showAllIssuesInProgress;
    },
    setStatusColor(status) {
      switch (status) {
        case "critical":
          return "error";
        case "warning":
          return "warning";
        case "optimal":
          return "green";
      }
    },
    transformName,
    goToComponentPage(name) {
      const transformedName = this.transformName(name, "route", false);
      return {
        name: "SubsystemComponentDetail",
        params: {
          turbineId: this.$route.params.turbineId,
          componentName: transformedName,
        },
      };
    },
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (
          this.selectedColumns.length === this.headers.length ||
          this.selectedColumns.length === this.maxColumns
        ) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.headers;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.headers;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        const index = this.selectedColumns.indexOf(header);
        if (index !== -1) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          if (this.selectedColumns.length >= this.maxColumns) {
            this.selectedColumns.shift();
          }
          this.selectedColumns.push(header);
        }
      }
      this.ensureMaxColumns();
    },
    handleResize() {
      this.ensureMaxColumns();
    },
    ensureMaxColumns() {
      const width = window.innerWidth;
      this.maxColumns = width < 900 ? 4 : this.headers.length;
      if (this.selectedColumns.length > this.maxColumns) {
        this.selectedColumns = this.selectedColumns.slice(0, this.maxColumns);
      }
    },
  },
  mounted() {
    this.selectedColumns = this.headers;
    this.handleResize = debounce(this.handleResize, 200);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.systems-tab-container {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.all-issues {
  position: absolute;
  bottom: 0;
  right: 75px;
  min-width: 200px;
  border: 1px solid var(--v-mediumGray-base);
  border-radius: 6px;
  box-shadow: 2px 2px 8px 4px rgba(13, 13, 13, 0.3);
  background: var(--v-issueModal-base);
  z-index: 1000;
  padding: 0.5rem;
}

.open-in-new-icon {
  .v-icon:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
